var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "inspectManagerCategoryForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  !_vm.isConmunityUser
                    ? [
                        _vm.isAdd
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "regionId",
                                  label: "所属公司",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择所属公司",
                                      trigger: "change",
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "v-select2",
                                  _vm._b(
                                    {
                                      attrs: {
                                        width: _vm.width,
                                        placeholder: "所属公司",
                                      },
                                      on: { onChange: _vm.onRegionIdChange },
                                      model: {
                                        value: _vm.form.regionId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "regionId", $$v)
                                        },
                                        expression: "form.regionId",
                                      },
                                    },
                                    "v-select2",
                                    _vm.regionIdParam,
                                    false
                                  )
                                ),
                              ],
                              1
                            )
                          : _c("col2-item", {
                              attrs: {
                                label: "所属公司",
                                text: _vm.regionName,
                              },
                            }),
                      ]
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "巡查类型",
                          text: _vm.typeMap[_vm.form.type],
                        },
                      })
                    : _vm._e(),
                  !_vm.isAdd
                    ? _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "分类级别",
                          text: _vm.levelMap[_vm.form.level],
                        },
                      })
                    : _vm._e(),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "巡查类型",
                            prop: "type",
                            rules: {
                              required: true,
                              message: "当前选项不允许为空",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.typeOps, width: _vm.width },
                            on: { change: _vm.typeChange },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdd
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分类级别" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.levelOps, width: _vm.width },
                            model: {
                              value: _vm.form.level,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "level", $$v)
                              },
                              expression: "form.level",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.level === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上级分类",
                            prop: "parentId",
                            rules: [
                              {
                                required: true,
                                message: "当前选项不允许为空",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              options: _vm.firstOps,
                              width: _vm.width,
                              disabled: _vm.parentDisabled,
                            },
                            model: {
                              value: _vm.form.parentId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parentId", $$v)
                              },
                              expression: "form.parentId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称",
                        prop: "name",
                        rules: [
                          {
                            required: true,
                            message: "分类名称不允许为空",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入分类名称",
                          width: 394,
                          maxlength: 30,
                          disabled: _vm.nameDisabled,
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查频次", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "period",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  options: _vm.periodOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.period,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "period", $$v)
                                  },
                                  expression: "form.period",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "periodNum",
                                rules: {
                                  required: true,
                                  message: "内容不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "7",
                                  min: 0,
                                  max: 99,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.periodNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "periodNum", $$v)
                                  },
                                  expression: "form.periodNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("如：“日、3”表示每日巡查3次"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "巡查间隔", required: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "content-wrapper" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespan",
                                rules: {
                                  required: true,
                                  message: "内容不允许为空",
                                  trigger: "blur",
                                },
                              },
                            },
                            [
                              _c("v-input-number", {
                                staticClass: "right-wrapper",
                                attrs: {
                                  controls: "",
                                  "controls-position": "right",
                                  placeholder: "1",
                                  min: 0,
                                  max: 9999999999,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.timespan,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespan", $$v)
                                  },
                                  expression: "form.timespan",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "timespanUnit",
                                rules: {
                                  required: true,
                                  message: "当前选项不允许为空",
                                  trigger: "change",
                                },
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: _vm.timespanOps,
                                  width: _vm.width,
                                },
                                model: {
                                  value: _vm.form.timespanUnit,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timespanUnit", $$v)
                                  },
                                  expression: "form.timespanUnit",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "right-tip" }, [
                            _vm._v("两次巡查间隔最小时间"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作备注" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "请输入操作备注", width: 394 },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }