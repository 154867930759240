<!--
 * @Description: 巡查管理--巡查分类--表单页面
 * @Author: 小广
 * @Date: 2019-05-29 18:33:57
 * @LastEditors: 小广
 * @LastEditTime: 2020-06-03 16:22:23
 -->
<template>
  <div class="inspectManagerCategoryForm-wrapper">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基本信息">
          <template v-if="!isConmunityUser">
            <el-form-item v-if="isAdd" prop="regionId" label="所属公司" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
              <v-select2 :width="width" v-model="form.regionId" placeholder="所属公司" v-bind="regionIdParam" @onChange="onRegionIdChange" />
            </el-form-item>
            <col2-item v-else label="所属公司" :text="regionName"></col2-item>
          </template>
          <col2-item
            v-if="!isAdd"
            :span="24"
            label="巡查类型"
            :text="typeMap[form.type]"
          />
          <col2-item
            v-if="!isAdd"
            :span="24"
            label="分类级别"
            :text="levelMap[form.level]"
          />
          <el-form-item
            v-if="isAdd"
            label="巡查类型"
            prop="type"
            :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }"
          >
            <v-select
              v-model="form.type"
              :options="typeOps"
              :width="width"
              @change="typeChange"
            ></v-select>
          </el-form-item>
          <el-form-item v-if="isAdd" label="分类级别">
            <v-select
              v-model="form.level"
              :options="levelOps"
              :width="width"
            ></v-select>
          </el-form-item>
          <el-form-item
            v-if="form.level === 2"
            label="上级分类"
            prop="parentId"
            :rules="[
              {
                required: true,
                message: '当前选项不允许为空',
                trigger: 'change'
              }
            ]"
          >
            <v-select
              v-model="form.parentId"
              :options="firstOps"
              :width="width"
              :disabled="parentDisabled"
            ></v-select>
          </el-form-item>
          <el-form-item
            label="分类名称"
            prop="name"
            :rules="[
              { required: true, message: '分类名称不允许为空', trigger: 'blur' }
            ]"
          >
            <v-input
              v-model="form.name"
              placeholder="请输入分类名称"
              :width="394"
              :maxlength="30"
              :disabled="nameDisabled"
            ></v-input>
          </el-form-item>
          <el-form-item label="巡查频次" required>
              <div class="content-wrapper">
             <el-form-item prop="period" :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
                <v-select
                class="right-wrapper"
                v-model="form.period"
                :options="periodOps"
                :width="width"
              ></v-select>
              </el-form-item>
            <el-form-item prop="periodNum"
              :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
                <v-input-number
                controls
                v-model="form.periodNum"
                controls-position="right"
                placeholder="7"
                :min="0"
                :max="99"
                :width="width"
              ></v-input-number>
              </el-form-item>
            <span class="right-tip">如：“日、3”表示每日巡查3次</span>
            </div>
          </el-form-item>
          <el-form-item label="巡查间隔" required>
              <div class="content-wrapper">
              <el-form-item prop="timespan"
              :rules="{ required: true, message: '内容不允许为空', trigger: 'blur' }">
                 <v-input-number
                  controls
                  v-model="form.timespan"
                  class="right-wrapper"
                  controls-position="right"
                  placeholder="1"
                  :min="0"
                  :max="9999999999"
                  :width="width"
                ></v-input-number>
              </el-form-item>
              <el-form-item prop="timespanUnit" :rules="{ required: true, message: '当前选项不允许为空', trigger: 'change' }">
                <v-select
                v-model="form.timespanUnit"
                :options="timespanOps"
                :width="width"
              ></v-select>
              </el-form-item>
            <span class="right-tip">两次巡查间隔最小时间</span>
            </div>
          </el-form-item>
          <el-form-item label="操作备注">
            <v-textarea
              v-model="form.remark"
              placeholder="请输入操作备注"
              :width="394"
            ></v-textarea>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import {
  getCategoryFirstURL,
  getCategoryDetailURL,
  handleInspectCategoryURL
} from './api'
import {
  typeOps,
  typeMap,
  periodOps,
  levelOps,
  levelMap,
  timespanOps
} from './map'
import { Col2Block, Col2Item, Col2Detail } from 'components/bussiness'
import { regionParams } from 'common/select2Params'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Item,
    Col2Detail
  },
  data () {
    return {
      width: 180,
      typeOps: typeOps(),
      typeMap: typeMap,
      levelOps: levelOps,
      periodOps: periodOps.slice(1, periodOps.length),
      levelMap: levelMap,
      timespanOps: timespanOps,
      regionIdParam: regionParams,
      regionName: '',
      parentId: undefined,
      firstOps: [],
      form: {
        id: undefined,
        level: 1,
        // 二级分类时，必填（一级分类的id）
        parentId: undefined,
        // 频次类型
        period: 1,
        // 频次
        periodNum: undefined,
        // 间隔时间
        timespan: undefined,
        // 间隔类型 1小时/2日
        timespanUnit: 1,
        name: '',
        // 默认取typeOps里第一个
        type: undefined,
        remark: '',
        regionId: undefined
      },
      submitConfig: {
        queryUrl: '',
        submitUrl: handleInspectCategoryURL,
        submitMethod: 'PUT'
      },
      // 一级分类id
      isAdd: false
    }
  },

  computed: {
    isConmunityUser () {
      const userType = String(this.$store.state.userInfo.userType)
      // return userType === '101' || userType === '102'
      return userType === '101'
    },
    // 上级分类是否禁用
    parentDisabled () {
      // 当分类名称等于 “进场检查”或者“墙体阶段”或者“竣工验收”时，分类名称不可编辑。
      // 新增不要控制
      let disabled = false
      this.firstOps.forEach((item) => {
        if (item.text === '进场检查' || item.text === '墙体阶段' || item.text === '竣工验收') {
          if (this.parentId === item.value && !this.isAdd) {
            console.log(1111)
            disabled = true
          }
        }
      })
      return disabled
    },
    // 分类名称是否禁用
    nameDisabled () {
      if (this.form.name === '进场检查' || this.form.name === '墙体阶段' || this.form.name === '竣工验收' || this.parentDisabled) {
        return true
      }
      return false
    }
  },

  created () {
    // 默认新增
    const { id } = this.$route.query
    this.isAdd = id === undefined
    this.submitConfig.submitMethod = this.isAdd ? 'post' : 'put'
    this.form.type = this.typeOps[0].value
    let title = this.isAdd ? '新增分类' : '编辑分类'
    // 设置title
    this.$setBreadcrumb(title)
  },

  // 加载详情数据
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.submitConfig.queryUrl = `${getCategoryDetailURL}/${this.$route.query.id}`
      this.$refs.formPanel.getData()
    }
    Number(this.$store.state.userInfo.userType) === 101 && this.requestForFirstCategory()
  },

  methods: {

    update (data) {
      Object.keys(this.form).forEach(key => {
        if (data[key] !== undefined) {
          this.form[key] = data[key]
        }
      })
      // 上级分类是否禁用中使用
      this.parentId = data.parentId
      this.regionName = data.regionName
      if (data.level === 2) {
        // 为二级分类时，需要获取一级分类
        this.requestForFirstCategory()
      }
    },
    submitBefore (data) {
      if (data.level === 1) {
        delete data.parentId
      }
      return true
    },

    // 类型发生改变时，要重新获取一级分类
    typeChange (value) {
      this.firstOps = []
      this.form.parentId = undefined
      this.requestForFirstCategory()
    },

    // 根据类型获取一级分类
    async requestForFirstCategory () {
      // if (!this.form.regionId) {
      //   return
      // }
      let getParams = {
        params: {
          type: this.form.type,
          regionId: this.form.regionId || this.$store.state.userInfo.regionId
        }
      }
      const { data, status } = await this.$axios.get(getCategoryFirstURL, getParams)
      let tempList = []
      if (status === 100) {
        let list = data || []
        list && list.length && list.forEach(item => {
          tempList.push({
            text: item.name,
            value: item.categoryId
          })
        })
      }
      this.firstOps = tempList
      if (tempList.length && this.isAdd) {
        this.form.parentId = tempList[0].value
      }
    },

    onRegionIdChange (value, echo) {
      if (!echo) {
        this.firstOps = []
        this.form.parentId = undefined
        this.requestForFirstCategory()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inspectManagerCategoryForm-wrapper {
  .content-wrapper {
    display: flex;
    justify-content: flex-start;

    .right-wrapper {
      margin-right: 30px;
    }

    .right-tip {
      margin-left: 15px;
    }
  }
}
</style>
